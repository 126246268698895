import * as React from 'react';
import Contentcard from './Contentcard';
import Grid from '@mui/material/Unstable_Grid2';
import {Amplify} from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { listMovies } from './graphql/queries';
import { getCurrentUser } from 'aws-amplify/auth';
import NavBar from './NavBar';
import { Navigate } from 'react-router-dom';


export default function CrApp() {
    const [movies, setMovies] = React.useState([]);
    const [authenticated, setAuthenticated] = React.useState(false);

    React.useEffect(() => {
        fetchMovies();
        checkAuth();
    }, [authenticated]);


    const checkAuth = async () => {
        try {
            const { username, userId, signInDetails } = await getCurrentUser();
            if (userId != undefined){
                console.log(userId);
                setAuthenticated(true);
            }
        } catch (error) {
            console.log('error on checkAuth', error);
        }
    }
    const fetchMovies = async () => {

        try {
            const Secret_Val = process.env.REACT_APP_API_KEY;

            Amplify.configure({
            API: {
                GraphQL: {
                endpoint: 'https://5by6pfni5ffj5m6vubaucccscm.appsync-api.us-east-2.amazonaws.com/graphql',
                region: 'us-east-2',
                defaultAuthMode: 'apiKey',
                apiKey: Secret_Val
                }
            }
            });
            const client = generateClient();
            
            const movieData = await client.graphql({ query: listMovies});
            const movieList = movieData.data.listMovies.items;
            setMovies(movieList);
            

        } catch (error) {
            console.log('error on fetching movies', error)
        }
    
    }
    if (true) {
        return (
            <>
                <NavBar />
                <Grid container spacing={2} sx={{paddingLeft:'2%', paddingRight:'2%'}}>
                        {movies.map((movie) => (
                        <Contentcard 
                        image={movie.image_url}
                        videoURL={movie.name}
                        />
                    ))}
                </Grid>
            </>
        );
    } else {
        return (
            <>
                <Navigate to="/home" replace={true}/>
            </>
        )
    }
}
